.slide {
    @apply w-full text-center overflow-hidden transition-all transition-[500ms];
}

.slide-indicator {
    animation: slide-time infinite 4s ease;
}

@keyframes slide-time {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@keyframes game-state-indicator {
    0% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

.run-timer {
    animation-name: game-state-indicator;
    animation-play-state: running;
    animation-fill-mode: forwards;
}
