@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
    @apply text-gray-800;
    font-family: Raleway, sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
    transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb {
    @apply bg-gradient-accent opacity-70 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
    @apply opacity-10;
}

.page {
    @apply container mt-5 mx-auto min-h-[75vh] md:min-h-[80vh];
}

a {
    position: relative;
}

a::after {
    @apply bg-accent-dark;
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    bottom: 0;
    height: 1px;
    transition: width .3s ease;
}

a:hover {
    @apply from-accent-light transition-colors;
}

a:not(.non-und):hover::after {
    width: 100%;
}

.form-control {
    @apply rounded-full border border-gray-300 px-1 py-2 transition outline-0 focus:border focus:border-accent-light invalid:border-red-500
}

.form-invalid {
    @apply border-red-500;
}

.btn {
    @apply bg-gradient-accent px-3 py-2 rounded-full transition text-white cursor-pointer hover:shadow-lg focus:scale-105 outline-0 focus:outline focus:outline-white active:scale-95 disabled:opacity-50 disabled:grayscale
}

.bg-gradient-accent {
    @apply bg-gradient-to-br from-accent-light to-accent-dark;
}

.form-btn-inline {
    @apply rounded-full border border-gray-300
}

.form-btn-inline input {
    border: none;
}

.header {
    @apply text-xl font-semibold text-accent-dark;
    /*    TODO: Провести рефракторинг заголовков*/
}

.card {
    @apply shadow-straight p-3 rounded-xl;
    /*    TODO: Провести рефракторинг карточек*/
}

.toggle-checkbox:checked {
    @apply right-0 border-accent-light;
    right: 0;
}

.toggle-checkbox:checked + .toggle-label {
    @apply bg-gradient-accent;
}

.round-avatar {
    /*TODO: Рефракторинг аватаров*/
    @apply rounded-full border border-gray-300 aspect-square overflow-hidden;
}

.avatar-accent-border{
    @apply border-2 border-accent-light
}

.spinner {
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;
}

.spinner .path {
    stroke: #0075c8;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animate-fade{
    animation: fade 300ms ease forwards;
}
