@keyframes float {
    0%, 100% {
        transform: none;
    }
    25% {
        transform: translate(calc(1.5 * 6px), calc(1.5 * -3px));
    }
    55% {
        transform: translate(calc(1.5 * -1px), calc(1.5 * 10px));
    }
    70% {
        transform: translate(calc(1.5 * 8px), calc(1.5 * -10px));
    }
    85% {
        transform: translate(calc(1.5 * -5px), calc(1.5 * 4px));
    }
}

.floating-meme {
    animation: float 15s infinite ease;
}

#fm-1 {
    -webkit-animation-delay: -5s;
    animation-delay: -5s;
    top: 25%;
    left: 3%;
}

#fm-2 {
    -webkit-animation-delay: -8s;
    animation-delay: -8s;
    top: 46%;
    right: 7%;
}

#fm-3 {
    -webkit-animation-delay: -3s;
    animation-delay: -3s;
    bottom: 12%;
    left: 5%;
}
